<template>
  <div>
    <v-container>
      <h1 class="primary--text pl-0">
        {{ $t("navbar.navigation.downloadRequests") }}
      </h1>
      <Breadcrumb
        v-if="$vuetify.breakpoint.smAndUp"
        :items="breadcrumb"
        class="custom-breadcrumb"
      />
      <!-- <div class="select py-6">
        <v-select
          :label="$t('sort')"
          outlined
          dense
          solo
          flat
          class="ps-4 pt-0"
          color="secondary darken-1"
          :items="sortOptions"
          :value="sortFilter"
          hide-details
        />
      </div> -->
      <v-data-table
        v-if="orders"
        :headers="headers"
        :items="orders"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            color="primary"
            text
            small
            @click.stop="clicked(item)"
            class="text-capitalize"
            >{{ $t("downloadRequest.viewDetail") }}</v-btn
          >
          <!-- <v-btn
            v-if="item.orderStatusId == 6 || item.orderStatusId == 8"
            color="primary"
            text
            small
            :href="'/ebsn/api/theone-order/download?order_id=' + item.orderId"
            class="text-capitalize"
            >Download</v-btn
          > -->
        </template>
        <template v-slot:[`item.addDate`]="{ item }">
          {{ formatDate(item.addDate) }}
        </template>
      </v-data-table>
      <div class="d-flex justify-start">
        <v-pagination
          v-if="orderPager && orderPager.totPages > 1"
          :value="pageFilter"
          :page="orderPager.selPage"
          :length="orderPager.totPages ? orderPager.totPages : 0"
          :totalVisible="8"
          @next="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import { mapGetters } from "vuex";
import OrderService from "~/service/orderService";

export default {
  name: "DownloadRequests",
  components: { Breadcrumb },
  data() {
    return {
      orders: [],
      orderPager: {},
      breadcrumb: [
        {
          text: "Home",
          disabled: false,
          to: "/"
        },
        {
          text: "Profilo",
          disabled: false,
          to: "/profile/profile-update"
        },
        {
          text: "Richieste di download",
          disabled: true,
          to: "/"
        }
      ],
      sortOptions: [
        {
          value: "alf_asc",
          text: "A-Z"
        },
        {
          value: "alf_dsc",
          text: "Z-A"
        }
      ],
      sortFilter: null,
      headers: [
        {
          text: this.$t("orders.header.orderId"),
          align: "start",
          sortable: false,
          value: "orderId"
        },
        {
          text: this.$t("downloadRequest.requestDate"),
          align: "start",
          sortable: false,
          value: "addDate"
        },
        {
          text: this.$t("downloadRequest.state"),
          align: "center",
          value: "orderStatusDescr",
          sortable: false
        },
        {
          text: this.$t("downloadRequest.totalItems"),
          align: "center",
          value: "grossTotal",
          sortable: false
        },
        {
          text: this.$t("common.action"),
          align: "center",
          value: "action",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    clicked(item) {
      this.$router.push({ path: "/profile/orders/" + item.orderId });
    },
    reload() {
      OrderService.getOrders(
        this.pageFilter,
        process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
        true
      ).then(data => {
        this.orders = data.orders;
        this.orderPager = data.page;
      });
    },
    formatDate(date) {
      const dateObj = new Date(date);
      console.log(dateObj);
      const dd = String(dateObj.getDate()).padStart(2, "0");
      const mm = String(dateObj.getMonth() + 1).padStart(2, "0");
      const yyyy = dateObj.getFullYear();
      const hh = dateObj.getHours();
      const min = String(dateObj.getMinutes()).padStart(2, "0");
      return "" + dd + "/" + mm + "/" + yyyy + " " + hh + ":" + min;
    },
    handlePageFilter(page) {
      this.$store.dispatch("category/setFilterPage", page);
      this.reload();
      this.$vuetify.goTo(0);
    }
  },
  created() {
    this.reload();
  },
  metaInfo() {
    return {
      title: this.$t("navbar.navigation.downloadRequests")
    };
  }
};
</script>
<style scoped lang="scss">
.select {
  width: 180px;
}
.custom-breadcrumb {
  margin-left: -15px !important;
}
</style>
